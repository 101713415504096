export default {
	getDateRangeHelper: (state) => (key) => {
		const delimiter = ' — '
		const range = {
			start: null,
			end: null
		}

		if (state[key]) {
			if (state[key].indexOf(delimiter) > -1) {
				const array = state[key].split(delimiter)
				range.start = array[0] || null
				range.end = array[1] || null
			} else {
				range.start = state[key]
				range.end = state[key]
			}
		}

		return range
	},
	getLoadingDateRange: (state, getters) => getters.getDateRangeHelper('loadingRange'),
	getPaidDateRange: (state, getters) => getters.getDateRangeHelper('paidRange'),
	getExpirationDateRange: (state, getters) => getters.getDateRangeHelper('expirationRange'),
	getFilterArgs: (state, getters) => {
		const payloadForFilter = {
			'loading-from': getters.getLoadingDateRange.start,
			'loading-to': getters.getLoadingDateRange.end,
			'paid-from': getters.getPaidDateRange.start,
			'paid-to': getters.getPaidDateRange.end,
			'expiration-from': getters.getExpirationDateRange.start,
			'expiration-to': getters.getExpirationDateRange.end,
			'global-search': state.liveSearch || null,
			'ttn-track-from': state.ttnTrackFrom,
			'ttn-track-to': state.ttnTrackTo,
			'docs-track-from': state.docsTrackFrom,
			'docs-track-to': state.docsTrackTo,
			'checkbox-filter[client-debt]': state['checkbox-filter'].clientDebt,
			'custom-filter[expiration]': state['checkbox-filter'].expiration,
			'custom-filter[transporter_expiration]': state['checkbox-filter'].expiration_transporter,
			'checkbox-filter[transporter-debt]': state['checkbox-filter'].transporterDebt,
			'checkbox-filter[client-paid]': state['checkbox-filter'].clientPaid,
			'checkbox-filter[transporter-paid]': state['checkbox-filter'].transporterPaid,
			'checkbox-filter[transporter-partly-paid]': state['checkbox-filter'].transporterPartlyPaid,
			'checkbox-filter[doc-send-true]': state['checkbox-filter'].docSend,
			'checkbox-filter[doc-send-false]': state['checkbox-filter'].docSendFalse,
			'checkbox-filter[doc-get-false]': state['checkbox-filter'].docGetFalse,
			'checkbox-filter[ttn-true]': state['checkbox-filter'].ttn,
			'checkbox-filter[ttn-false]': state['checkbox-filter'].ttnFalse,
			'checkbox-filter[rnn-false]': state['checkbox-filter'].rnnFalse,
			'checkbox-filter[is-order]': state['checkbox-filter'].noApplications,
			'checkbox-filter[not-bills]': state['checkbox-filter'].notBills,
			'checkbox-filter[is_client_court]': state['checkbox-filter'].client_litigation,
			'checkbox-filter[is_transporter_court]': state['checkbox-filter'].transporter_ligation,
			my_clients: state['checkbox-filter'].allMyClientsDeals,
			...state.additionalFilterQuery
		}

		if (state.selectedClient) {
			payloadForFilter['filter[client_company][managers][id]'] = state.selectedClient.value
		}
		if (state.selectedSeller) {
			payloadForFilter['filter[client_company][portfolio_manager][id]'] = state.selectedSeller
		}

		payloadForFilter['transportation_type_id'] = state.transportationType || null

		const notInAdditionalFilter = !state.additionalFilterQuery['filter[company_id]'] &&
    	!state.additionalFilterQuery['filter[driver_id]'] &&
    	!state.additionalFilterQuery['filter[contact_id]']

		const notInQueryFilter = !state.routerQuery['filter[company_id]'] &&
    	!state.routerQuery['filter[driver_id]'] &&
    	!state.routerQuery['filter[contact_id]']

		if (notInAdditionalFilter && notInQueryFilter) {
			payloadForFilter['custom-filter[user]'] = state.selectedUsers.map(i => i.value).join(',')
			payloadForFilter['custom-filter[department]'] = state.department
		}

		for (const [queryKey, queryValue] of Object.entries(state.routerQuery)) {
			payloadForFilter[queryKey] = queryValue
		}

		if (state.byFields.length > 0) {
			const parsedFilterByFields = state.byFields.reduce((memo, item) => {
				let key = item.field ? item.field : null
				if (!memo[key]) {
					memo[key] = []
				}
				memo[key].push(item.value)

				return memo
			}, {})

			for (let field in parsedFilterByFields) {
				if (field === 'id') {
					let str = 'filter[' + field + ']'
					payloadForFilter[str] = parsedFilterByFields[field].join(';')
				} else {
					let str = 'search[' + field + ']'
					payloadForFilter[str] = parsedFilterByFields[field].join(';')
				}
			}
		}

		return payloadForFilter

	}
}
